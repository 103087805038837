import React from 'react';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';
import '../../static/css/sales-letter.css';
import { FunnelProvider } from '../context/FunnelContext';
import loadable from '@loadable/component';
import { useFunnelContext } from '../hooks/useFunnelContext';
import { useProductSelection } from '../hooks/useProductSelection';
import StagingIndicator from '../components/StagingIndicator/StagingIndicator';
import { useViewTracking } from '../hooks/useViewTracking';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function SalesLetterTemplate(props) {
  const {
    data: { allBuilderModels }
  } = props;

  const {
    oneSalesLetter: {
      data: { design, currentCategory },
      content: funnelContent
    }
  } = allBuilderModels;

  const context = {
    ...useFunnelContext(props, 0, currentCategory),
    pageDesign: design ?? `design2`
  };

  useViewTracking();
  useProductSelection(context);

  return (
    <>
      <FunnelProvider value={context}>
        <Helmet
          bodyAttributes={{
            class: 'sales-letter-1'
          }}
        >
          <title>
            {(funnelContent && funnelContent.data.title) || defaultTitle}
          </title>
          <meta
            name="description"
            content={
              (funnelContent && funnelContent.data.description) ||
              defaultDescription
            }
          />
          <meta name="funnel-id" content={funnelContent.id} />
          <meta
            name="product-selector-id"
            content={funnelContent.data.products.id}
          />
          <meta
            name="magic-key"
            content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
          />
        </Helmet>
        {/** name of the model is landing page, change it if you decided to build*/}
        <BuilderComponent
          model="sales-letter"
          content={funnelContent}
          data={{
            ...context
          }}
        />
      </FunnelProvider>
      {process.env.GATSBY_FUNNEL_STAGING && <StagingIndicator />}
    </>
  );
}

export const query = graphql`
  query($funnelId: String) {
    allBuilderModels {
      oneSalesLetter(query: { id: $funnelId }, options: { cachebust: true }) {
        content
        data {
          design
          currentCategory
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;

export default SalesLetterTemplate;
